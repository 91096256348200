/* purgecss start ignore */
@tailwind base;
@tailwind components;

html,
body,
#__next {
  height: 100%;
}

* {
  @apply transition-all;
}

.react-tiny-popover-container {
    @apply transition-none;
}

.color-primary {
  @apply bg-indigo-600;

  &:hover {
    @apply bg-indigo-500;
  }

  &:active {
    @apply bg-indigo-700;
  }

  &:focus {
    @apply border-indigo-700 outline-none ring;
  }
}

.color-secondary {
  @apply bg-gray-600;

  &:hover {
    @apply bg-gray-500;
  }

  &:active {
    @apply bg-gray-700;
  }

  &:focus {
    @apply border-gray-700 outline-none ring;
  }
}

select,
input {
  @apply transition-none transition-colors focus:ring-indigo-500 focus:border-indigo-500 block rounded-md sm:text-sm border-gray-300 dark:border-gray-600 dark:bg-gray-800 dark:text-gray-50;
}

/* purgecss end ignore */
@tailwind utilities;
